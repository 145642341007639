import { ReactNode, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { trackClick, trackPage } from '@zastrpay/analytics';

import { ActiveKycRequestType } from './ActiveKycRequestProvider';
import { KycRequestPage } from './KycRequestPage';
import { useKycRequest } from './KycRequestProvider';
import { isRequiredKycRequest, KycRequest } from './models';
import { EmailVerificationRequest } from './types/EmailVerificationRequest';
import { InitialVerificationRequest } from './types/InitialVerificationRequest';
import { PoaDocumentRequest } from './types/PoaDocumentRequest';
import { PoiDocumentRequest } from './types/PoiDocumentRequest';
import { SelfDeclaredLinksQuestionnaireRequest } from './types/SelfDeclaredLinksQuestionnaireRequest';
import { SelfDeclaredPepQuestionnaireRequest } from './types/SelfDeclaredPepQuestionnaireRequest';
import { SofwDocumentRequest } from './types/SofwDocumentRequest';
import { SofwQuestionnaireRequest } from './types/SofwQuestionnaireRequest';

export type KycRequestData = {
    email?: string;
};

export type KycRequestProps = {
    request: KycRequest;
    mode: 'alert' | 'page';
    onAction: (data?: KycRequestData) => void;
};

const getType = (props: KycRequestProps): ReactNode => {
    switch (props.request.type) {
        case 'EmailVerification':
            return <EmailVerificationRequest {...props} />;
        case 'InitialVerification':
            return <InitialVerificationRequest {...props} />;
        case 'PoaDocument':
            return <PoaDocumentRequest {...props} />;
        case 'PoiDocument':
            return <PoiDocumentRequest {...props} />;
        case 'SofDocument':
        case 'SowDocument':
            return <SofwDocumentRequest {...props} />;
        case 'SofQuestionnaire':
        case 'SowQuestionnaire':
            return <SofwQuestionnaireRequest {...props} />;
        case 'SelfDeclaredLinksQuestionnaire':
            return <SelfDeclaredLinksQuestionnaireRequest {...props} />;
        case 'SelfDeclaredPepQuestionnaire':
            return <SelfDeclaredPepQuestionnaireRequest {...props} />;
    }
};

export type ActiveKycRequestProps = {
    kycRequest: KycRequest;
    mode: ActiveKycRequestType;
    skippable?: boolean;
    skipAlertDisplay?: boolean;
};

export const ActiveKycRequest = ({ kycRequest, mode, skippable = false, skipAlertDisplay = false }: ActiveKycRequestProps): ReactNode => {
    const navigate = useNavigate();
    const { seen } = useKycRequest();

    const shouldSkipAlert =
        skipAlertDisplay && mode === 'page' && (kycRequest.state === 'Pending' || kycRequest.state === 'PendingOverdue');

    useEffect(() => {
        trackPage('kyc_request_info', { kyc_req_type: kycRequest.type, kyc_req_required: isRequiredKycRequest(kycRequest) });
        seen(kycRequest);

        if (shouldSkipAlert) {
            handleAction();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAction = useCallback(
        (data?: KycRequestData) => {
            trackClick('kyc_request_info', 'upload_now', { kyc_req_type: kycRequest.type });

            switch (kycRequest.type) {
                case 'EmailVerification':
                    navigate(`/kyc-request/${kycRequest.id}/verify-email`, { state: { email: data?.email } });
                    return;
                case 'InitialVerification':
                    navigate(`/kyc-request/${kycRequest.id}/finalize-registration`);
                    return;
                case 'PoaDocument':
                case 'PoiDocument':
                case 'SofDocument':
                case 'SowDocument':
                    navigate(`/kyc-request/${kycRequest.id}/document-upload`);
                    return;
                case 'SofQuestionnaire':
                case 'SowQuestionnaire':
                case 'SelfDeclaredLinksQuestionnaire':
                case 'SelfDeclaredPepQuestionnaire':
                    navigate(`/kyc-request/${kycRequest.id}/questionnaire`);
                    return;
            }
        },
        [kycRequest.id, kycRequest.type, navigate],
    );

    if (!kycRequest || shouldSkipAlert) {
        return null;
    }

    return (
        <KycRequestPage kycRequest={kycRequest} mode={mode} skippable={skippable}>
            {getType({ request: kycRequest, mode, onAction: handleAction })}
        </KycRequestPage>
    );
};
