import React, { createContext, PropsWithChildren, useContext } from 'react';

import { KycRequest } from './models';

type CurrentKycRequestProviderProps = {
    kycRequest: KycRequest;
};

type CurrentKycRequestContextProps = CurrentKycRequestProviderProps;

const CurrentKycRequestContext = createContext<CurrentKycRequestContextProps | null>(null);

export const CurrentKycRequestProvider: React.FC<PropsWithChildren<CurrentKycRequestProviderProps>> = ({ kycRequest, children }) => {
    return <CurrentKycRequestContext.Provider value={{ kycRequest: kycRequest! }}>{children}</CurrentKycRequestContext.Provider>;
};

export const useCurrentKycRequest = (): CurrentKycRequestProviderProps => {
    const context = useContext(CurrentKycRequestContext);

    if (!context) {
        throw new Error('useCurrentKycRequest must be used within a CurrentKycRequestContext');
    }

    return context;
};
