export const pick = <T extends object, K extends keyof T>(base: T, ...keys: K[]): Pick<T, K> => {
    const entries = keys.map((key) => [key, base[key]]);
    return Object.fromEntries(entries);
};

/**
 * Compares two arrays for equality, regardless of order.
 *
 * @param arr1 - First array
 * @param arr2 - Second array
 * @param comparator - Optional comparator function for sorting.
 * @returns True if both arrays contain the same elements, false otherwise.
 */
export const arraysEqual = <T>(arr1: T[], arr2: T[], comparator?: (a: T, b: T) => number): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Make copies so we don't mutate the original arrays, then sort them.
    const sortedArr1 = [...arr1].sort(comparator);
    const sortedArr2 = [...arr2].sort(comparator);

    return sortedArr1.every((value, index) => value === sortedArr2[index]);
};
