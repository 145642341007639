import { Trans, useTranslation } from 'react-i18next';

import { FormattedDate } from '@zastrpay/components';

import { KycRequestProps } from '../ActiveKycRequest';
import { KycRequestAlert } from '../KycRequestAlert';

export type SelfDeclaredLinksQuestionnaireRequestProps = {} & KycRequestProps;

export const SelfDeclaredLinksQuestionnaireRequest: React.FC<SelfDeclaredLinksQuestionnaireRequestProps> = ({
    request,
    mode,
    onAction,
}) => {
    const { t } = useTranslation('kyc-requests');

    if (!request.assignment) {
        throw new Error('Kyc Request of type SelfDeclaredLinks is missing assignment');
    }

    return (
        <KycRequestAlert
            kycRequest={request}
            title={t('selfDeclaredLinksQuestionnaire.title')}
            action={t('selfDeclaredLinksQuestionnaire.action')}
            onAction={onAction}
            mode={mode}
        >
            <Trans
                t={t}
                i18nKey="selfDeclaredLinksQuestionnaire.description"
                context={request.state === 'PendingOverdue' ? 'Overdue' : undefined}
                components={{ dueOn: <FormattedDate value={request.dueOn} format="date" /> }}
            />
        </KycRequestAlert>
    );
};
