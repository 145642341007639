import { Trans, useTranslation } from 'react-i18next';

import { FormattedDate } from '@zastrpay/components';

import { KycRequestProps } from '../ActiveKycRequest';
import { KycRequestAlert } from '../KycRequestAlert';

export type SofwDocumentRequestProps = {} & KycRequestProps;

export const SofwDocumentRequest: React.FC<SofwDocumentRequestProps> = ({ request, mode, onAction }) => {
    const { t } = useTranslation('kyc-requests');

    if (request.state === 'DataRejected') {
        const rejectionReason =
            request.stateDetails?.dataMismatchReason ??
            request.stateDetails?.invalidDocumentReason ??
            request.stateDetails?.poorDocumentQualityReason;

        return (
            <KycRequestAlert
                kycRequest={request}
                title={t('sofwDocument.title')}
                action={t('sofwDocument.action')}
                onAction={onAction}
                mode={mode}
            >
                {t('rejected.subTitle', { context: rejectionReason })}
            </KycRequestAlert>
        );
    }

    return (
        <KycRequestAlert
            kycRequest={request}
            title={t('sofwDocument.title')}
            action={t('sofwDocument.action')}
            onAction={onAction}
            mode={mode}
        >
            <Trans
                t={t}
                i18nKey="sofwDocument.description"
                context={request.state === 'PendingOverdue' ? 'Overdue' : undefined}
                components={{ dueOn: <FormattedDate value={request.dueOn} format="date" /> }}
            />
        </KycRequestAlert>
    );
};
