import { Subtitle2 } from '@fluentui/react-components';
import { DocumentCheckmarkRegular } from '@fluentui/react-icons';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BulletList, Dialog, Link } from '@zastrpay/components';

import { KycRequestProps } from '../ActiveKycRequest';
import { KycRequestAlert } from '../KycRequestAlert';

const idDocuments = ['IdCard', 'Passport', 'ResidencePermit', 'OtherDocument'] as const;

export type InitialVerificationRequestProps = {} & KycRequestProps;

export const InitialVerificationRequest: React.FC<InitialVerificationRequestProps> = ({ request, mode, onAction }) => {
    const { t } = useTranslation('kyc-requests');
    const [initialVerificationDetails, setInitialVerificationDetails] = useState(false);

    return (
        <KycRequestAlert
            kycRequest={request}
            title={t('initialVerification.title')}
            action={t('initialVerification.action')}
            onAction={onAction}
            mode={mode}
        >
            <Trans
                t={t}
                i18nKey="initialVerification.description"
                components={{ showMoreLink: <Link inline onClick={() => setInitialVerificationDetails(true)} /> }}
            />
            <Dialog
                icon={<DocumentCheckmarkRegular />}
                title={t('initialVerification.acceptedDocuments')}
                open={initialVerificationDetails}
                onOpenChange={setInitialVerificationDetails}
                align="bottom"
            >
                <BulletList as="ul" size="small">
                    {idDocuments.map((doc) => (
                        <li key={`id-doc-${doc.toLowerCase()}`}>
                            <Trans t={t} i18nKey="initialVerification.idDocument" context={doc} components={{ title: <Subtitle2 /> }} />
                        </li>
                    ))}
                </BulletList>
            </Dialog>
        </KycRequestAlert>
    );
};
