import { makeStyles } from '@fluentui/react-components';
import { ErrorCircleRegular, InfoRegular } from '@fluentui/react-icons';
import { PropsWithChildren } from 'react';

import { AlertCard, Body, Button, Link } from '@zastrpay/components';
import { MessagePage } from '@zastrpay/layout';
import { tokens } from '@zastrpay/theme';

import { isRequiredKycRequest, KycRequest } from './models';

export type KycRequestAlertProps = {
    kycRequest: KycRequest;
    mode: 'alert' | 'page';
    title: string;
    action: string;
    onAction: () => void;
};

export const KycRequestAlert: React.FC<PropsWithChildren<KycRequestAlertProps>> = ({
    kycRequest,
    mode,
    action,
    title,
    children,
    onAction,
}) => {
    const classes = useStyles();

    const isRequired = kycRequest && isRequiredKycRequest(kycRequest);
    switch (mode) {
        case 'alert':
            return (
                <AlertCard type={isRequired ? 'error' : 'warning'} title={title}>
                    <Body align="start" className={classes.alertMessage}>
                        {children}
                    </Body>
                    {isRequired ? (
                        <Button className={classes.action} appearance="primary" size="large" onClick={onAction}>
                            {action}
                        </Button>
                    ) : (
                        <Link className={classes.link} onClick={onAction}>
                            {action}
                        </Link>
                    )}
                </AlertCard>
            );
        case 'page':
            return (
                <MessagePage
                    icon={isRequired ? <ErrorCircleRegular className={classes.overdue} /> : <InfoRegular className={classes.pending} />}
                    title={title}
                    action={action}
                    message={children}
                    onAction={onAction}
                />
            );
    }
};

const useStyles = makeStyles({
    link: {
        fontWeight: tokens.fontWeightSemibold,
        display: 'block',
        marginTop: tokens.spacingVerticalS,
        padding: '0',
    },
    action: {
        marginTop: tokens.spacingVerticalS,
        marginBottom: tokens.spacingVerticalXS,
    },
    overdue: {
        color: tokens.customPaletteRed,
    },
    pending: {
        color: tokens.customPaletteYellowDark,
    },
    alertMessage: {
        color: 'inherit',
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.lineHeightBase300,
    },
});
